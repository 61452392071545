import gql from 'graphql-tag'

export const MUTATION_UPDATE_SMS_CREDENTIALS = gql`
  mutation($id: ID!, $login: String!, $password: String!, $provider: String, $price: Float) {
    adminUpdateSMSCredentials(id: $id, login: $login, password: $password, provider: $provider, price: $price)
  }
`
export const MUTATION_UPDATE_RECOVERY_SMS_CREDENTIALS = gql`
  mutation($id: ID!, $login: String!, $password: String!, $provider: String, $price: Float) {
    adminUpdateRecoverySMSCredentials(id: $id, login: $login, password: $password, provider: $provider, price: $price)
  }
`
